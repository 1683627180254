<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="invoiceData === undefined">
      <h4 class="alert-heading">Error fetching invoice data</h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link class="alert-link" :to="{ name: 'apps-invoice-list' }">
          Invoice List
        </b-link>
        for other invoices.
      </div>

    </b-alert>
    <b-row v-if="invoiceData" class="invoice-add">
      <b-col cols="12" xl="9" md="8">
        <validation-observer ref="milestoneInvoiceValidation">
          <b-form v-on:submit.prevent="milestoneInvoiceValidationForm">
            <b-card no-body class="invoice-preview-card">
              <b-card-body class="invoice-padding pb-0">
                <div
                  class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                >
                  <div>
                    <div class="logo-wrapper">
                      <logo />
                    </div>
                    <div v-html="this.officeAddress"></div>

                    <p class="card-text mb-0">{{ this.officeEmail }}</p>
                    <p class="card-text mb-0">{{ this.officeMobile }}</p>
                  </div>

                  <div class="invoice-number-date mt-md-0 mt-2">
                    <div class="d-flex align-items-center">
                      <div>
                        <b-badge
                          v-if="invoiceInfo?.status == 'paid'"
                          variant="light-success"
                          >Paid</b-badge
                        >
                        <b-badge
                          v-else-if="invoiceInfo?.status == 'due'"
                          variant="light-danger"
                          f
                          >Due</b-badge
                        >
                      </div>
                    </div>

                    <div
                      class="d-flex align-items-center justify-content-md-end mb-1"
                    >
                      <h4 class="invoice-title">Invoice</h4>
                      <b-input-group
                        class="input-group-merge invoice-edit-input-group disabled"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="invoice-data-id"
                          v-model="invoiceId"
                          disabled
                        />
                      </b-input-group>
                    </div>

                    <div class="d-flex align-items-center mb-1">
                      <span class="title">Issue Date: </span>

                      <b-form-group>
                        <ValidationProvider
                          name="issue date"
                          v-slot="{ errors }"
                          vid="issue_date"
                          rules="required"
                        >
                          <flat-pickr
                            v-model="issueDate"
                            class="form-control invoice-edit-input"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </b-form-group>
                    </div>
                    <div class="d-flex align-items-center">
                      <span class="title"> Due Date: </span>
                      <b-form-group>
                        <ValidationProvider
                          name="due date"
                          v-slot="{ errors }"
                          vid="due_date"
                          rules="required"
                        >
                          <flat-pickr
                            v-model="dueDate"
                            class="form-control invoice-edit-input"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </b-card-body>

              <hr class="invoice-spacing" />
              <b-card-body class="invoice-padding pt-0">
                <b-row class="invoice-spacing">
                  <!-- Col: Invoice To -->
                  <b-col cols="12" xl="6" class="mb-lg-1">
                    <h5 class="mb-30">Invoice To:</h5>
                    <h6 class="mb-25">
                      {{
                        milestoneInfo?.project?.data?.lead?.data
                          ?.key_contact_person
                      }}
                    </h6>
                    <p class="card-text mb-25">
                      {{
                        milestoneInfo?.project?.data?.lead?.data?.company_name
                      }}
                    </p>
                    <p class="card-text mb-25">
                      Address:
                      {{ milestoneInfo?.project?.data?.lead?.data?.address }}
                    </p>
                    <p class="card-text mb-25">
                      Email:
                      {{ milestoneInfo?.project?.data?.lead?.data?.email }}
                    </p>
                    <p class="card-text mb-25">
                      Mobile:
                      {{ milestoneInfo?.project?.data?.lead?.data?.mobile }}
                    </p>
                  </b-col>

                  <!-- Col: Payment Details -->
                  <b-col
                    xl="6"
                    cols="12"
                    class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                  >
                    <div v-if="accountInfo">
                      <h6 class="mb-30">Payment Details:</h6>
                      <table>
                        <tbody>
                          <div>
                            <tr>
                              <td class="pr-1">Bank Name:</td>
                              <td>{{ accountInfo?.bank_name }}</td>
                            </tr>
                            <tr>
                              <td class="pr-1">Account Name:</td>
                              <td>{{ accountInfo?.name }}</td>
                            </tr>
                            <tr>
                              <td class="pr-1">Account Number:</td>
                              <td>
                                {{
                                  accountInfo?.account_number
                                    ? accountInfo?.account_number
                                    : "N/A"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pr-1">Routing Number:</td>
                              <td>
                                {{
                                  accountInfo?.routing_number
                                    ? accountInfo?.routing_number
                                    : "N/A"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pr-1">Country:</td>
                              <td>{{ accountInfo?.country?.data?.name }}</td>
                            </tr>
                            <tr>
                              <td class="pr-1">IBAN:</td>
                              <td>
                                {{
                                  accountInfo?.iban_number
                                    ? accountInfo?.iban_number
                                    : "N/A"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td class="pr-1">SWIFT code:</td>
                              <td>
                                {{
                                  accountInfo?.swift_code
                                    ? accountInfo?.swift_code
                                    : "N/A"
                                }}
                              </td>
                            </tr>
                          </div>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="invoice-spacing">
                  <!-- Col: Invoice To -->
                  <b-col cols="12" xl="6" class="mb-lg-1">
                    <h5 class="mb-30">Milestone Info:</h5>

                    <p class="card-text mb-25">
                      Title: {{ milestoneInfo?.title }}
                    </p>

                    <p class="card-text mb-25">
                      Total Amount: {{ milestoneInfo?.total_amount }}
                      {{ currency }}
                    </p>
                    <p class="card-text mb-25">
                      Total Vat Amount:
                      {{ milestoneInfo?.total_vat_amount }} {{ currency }}
                    </p>
                    <p class="card-text mb-25">
                      Total Tax Amount:
                      {{ milestoneInfo?.total_tax_amount }} {{ currency }}
                    </p>
                    <p class="card-text mb-25">
                      Grand Total Amount:
                      {{ milestoneInfo?.grand_amount }} {{ currency }}
                    </p>
                  </b-col>

                  <!-- Col: Payment Details -->
                  <b-col
                    xl="6"
                    cols="12"
                    class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                  >
                  </b-col>
                </b-row>
              </b-card-body>

              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{ height: trHeight }"
                >
                  <b-row
                    v-for="(item, index) in invoiceData.items"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >
                    <b-col cols="12">
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <b-col cols="12" lg="5"> Item </b-col>
                          <b-col cols="12" lg="3"> Amount </b-col>
                          <b-col cols="12" lg="2"> Qty </b-col>
                          <b-col cols="12" lg="2"> Price </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col cols="12" lg="5">
                            <b-form-group>
                              <ValidationProvider
                                name="item"
                                v-slot="{ errors }"
                                vid="item"
                                rules="required"
                              >
                                <b-form-input
                                  v-model="item.itemTitle"
                                  type="text"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="3">
                            <b-form-group>
                              <ValidationProvider
                                name="amount"
                                v-slot="{ errors }"
                                vid="amount"
                                rules="required|min_value:0"
                              >
                                <b-form-input
                                  v-model="item.cost"
                                  type="number"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="2">
                            <b-form-group>
                              <ValidationProvider
                                name="quantity"
                                v-slot="{ errors }"
                                vid="quantity"
                                rules="required|min_value:1"
                              >
                                <b-form-input
                                  v-model="item.qty"
                                  type="number"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </ValidationProvider>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="2">
                            <label class="d-inline d-lg-none">Price</label>
                            <p class="mb-1">
                              {{ item.cost * item.qty }} {{ currency }}
                            </p>
                          </b-col>
                          <b-col cols="12" lg="5">
                            <b-form-group>
                              <ValidationProvider
                                name="description"
                                v-slot="{ errors }"
                                vid="description"
                              >
                                <b-form-textarea
                                  v-model="item.description"
                                  class="mb-lg-0"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </ValidationProvider>
                            </b-form-group>

                            <label class="d-inline d-lg-none"
                              >Description</label
                            >
                          </b-col>
                        </b-row>

                        <div
                          class="d-flex flex-column justify-content-between border-left py-50 px-25"
                        >
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="
                              invoiceData.items.length > 1
                                ? removeItem(index)
                                : null
                            "
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <template v-if="invoiceInfo?.status == 'due'">
                  <div class="mt-1">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="primary"
                      @click="addNewItemInItemForm"
                    >
                      Add Item
                    </b-button>
                    <b-button
                      type="submit"
                      class="float-right"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="primary"
                    >
                      Update
                    </b-button>
                  </div>
                </template>
              </b-card-body>

              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="2"
                    order-md="1"
                  >
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper custom-width">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Sub Total:</p>
                        <p class="invoice-total-amount">
                          {{ subTotalAmount }} {{ currency }}
                        </p>
                      </div>
                      <!-- <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          <b-form-checkbox
                            v-model="vatTaxSelected"
                            @change="handleCheckboxClick"
                          >
                            <b>Include Vat Tax</b>
                          </b-form-checkbox>
                        </p>
                      </div> -->
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Vat:</p>

                        <b-form-group>
                          <ValidationProvider
                            name="vat amount"
                            v-slot="{ errors }"
                            vid="vat_amount"
                            rules="required|min_value:0"
                          >
                            <b-input-group :append="currency">
                              <b-form-input v-model="vatAmount" type="number" />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </ValidationProvider>
                        </b-form-group>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Tax:</p>

                        <b-form-group>
                          <ValidationProvider
                            name="tax amount"
                            v-slot="{ errors }"
                            vid="tax_amount"
                            rules="required|min_value:0"
                          >
                            <b-input-group :append="currency">
                              <b-form-input v-model="taxAmount" type="number" />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </ValidationProvider>
                        </b-form-group>
                      </div>

                      <hr class="my-50" />
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Grand Total:</p>
                        <p class="invoice-total-amount">
                          {{ grandTotalAmount }} {{ currency }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing" />

              <!-- Note -->
              <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">Note: </span>
                <b-form-textarea v-model="remarks" />
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <!-- Action Buttons -->
        <b-card>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            v-on:click="printInvoice"
          >
            Print
          </b-button>

          <!-- Button: Add Payment -->
          <template v-if="invoiceInfo?.status == 'due'">
            <b-button
              v-b-toggle.sidebar-invoice-add-payment
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
            >
              Collect Payment
            </b-button>
          </template>
        </b-card>
      </b-col>
    </b-row>

    <!-- Printed Invoice  -->
    <div id="content" style="width: 100%; font-family: Arial, sans-serif; margin: 0; padding: 0; display: none">
      <div style="width: 100%; padding: 20px; box-sizing: border-box;">
        <!-- Header -->
        <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
          <!-- Header: Left Content -->
          <div>
            <div style="margin-bottom: 10px;">
              <logo />
            </div>
            <p style="margin: 0;">{{ this.officeAddress }}</p>
            <p style="margin: 0;">{{ this.officeEmail }}</p>
            <p style="margin: 0;">{{ this.officeMobile }}</p>
          </div>

          <!-- Header: Right Content -->
          <div style="text-align: right;">
            <h4 style="margin: 0;">
              Invoice <span style="font-weight: normal;"># {{ invoiceId }}</span>
              <span v-if="invoiceInfo?.status == 'paid'" style="background-color: rgba(40, 199, 111, 0.12); color: #008000; padding: 2px 5px; margin-left: 5px;">Paid</span>
              <span v-else-if="invoiceInfo?.status == 'due'" style="background-color: rgba(234, 84, 85, 0.12); color: #ff0000; padding: 2px 5px; margin-left: 5px;">Due</span>
            </h4>
            <p style="margin: 0;">Date Issued: <b>{{ formatDate(issueDate) }}</b></p>
            <p style="margin: 0;">Due Date: <b>{{ formatDate(dueDate) }}</b></p>
          </div>
        </div>

        <hr style="margin: 20px 0;" />

        <!-- Invoice Client & Payment Details -->
        <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
          <!-- Invoice Client: Left Content -->
          <div>
            <h6 style="margin: 0 0 10px;">Invoice To:</h6>
            <h6 style="margin: 0;">{{ milestoneInfo?.project?.data?.lead?.data?.key_contact_person }}</h6>
            <p style="margin: 0;">{{ milestoneInfo?.project?.data?.lead?.data?.company_name }}</p>
            <p style="margin: 0;">Address: {{ milestoneInfo?.project?.data?.lead?.data?.address }}</p>
            <p style="margin: 0;">Email: {{ milestoneInfo?.project?.data?.lead?.data?.email }}</p>
            <p style="margin: 0;">Mobile: {{ milestoneInfo?.project?.data?.lead?.data?.mobile }}</p>
          </div>

          <!-- Payment Details: Right Content -->
          <div style="text-align: right;">
            <h6 style="margin: 0 0 10px;">Payment Details:</h6>
            <p style="margin: 0;">Total Due: <b>{{ milestoneInfo?.grand_amount }}</b></p>
            <p style="margin: 0;">Bank Name: {{ accountInfo?.bank_name }}</p>
            <p style="margin: 0;">Account Name: {{ accountInfo?.name }}</p>
            <p style="margin: 0;">Account Number: {{ accountInfo?.account_number }}</p>
            <p style="margin: 0;">Routing Number: {{ accountInfo?.routing_number }}</p>
            <p style="margin: 0;">Country: {{ accountInfo?.country?.data?.name }}</p>
            <p style="margin: 0;">IBAN: {{ accountInfo?.iban_number ? accountInfo?.iban_number : "N/A" }}</p>
            <p style="margin: 0;">SWIFT code: {{ accountInfo?.swift_code ? accountInfo?.swift_code : "N/A" }}</p>
          </div>
        </div>

        <!-- Invoice Items -->
        <h6 style="margin: 0 0 10px;">Invoice Items:</h6>
        <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
          <thead>
          <tr>
            <th style="padding: 5px; text-align: left;">Item Title</th>
            <th style="padding: 5px; text-align: left;">Cost</th>
            <th style="padding: 5px; text-align: left;">Quantity</th>
            <th style="padding: 5px; text-align: left;">Price</th>
            <th style="padding: 5px; text-align: left;">Description</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in invoiceData.items" :key="index">
            <td style="padding: 5px; border: 1px solid #ddd;">{{ item.itemTitle }}</td>
            <td style="padding: 5px; border: 1px solid #ddd;">{{ item.cost }}</td>
            <td style="padding: 5px; border: 1px solid #ddd;">{{ item.qty }}</td>
            <td style="padding: 5px; border: 1px solid #ddd;">{{ item.price }}</td>
            <td style="padding: 5px; border: 1px solid #ddd;">{{ item.description }}</td>
          </tr>
          </tbody>
        </table>

        <!-- Invoice Total -->
        <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
          <div>
            <!-- <p style="margin: 0;">Salesperson: Alfie Solomons</p> -->
          </div>

          <div style="text-align: right;">
            <table style="width: 100%; border-collapse: collapse;">
              <tbody>
              <tr>
                <td style="padding: 5px; border: 1px solid #ddd;">Subtotal:</td>
                <td style="padding: 5px; border: 1px solid #ddd;"><b>{{ subTotalAmount }} {{ currency }}</b></td>
              </tr>
              <tr>
                <td style="padding: 5px; border: 1px solid #ddd;">VAT:</td>
                <td style="padding: 5px; border: 1px solid #ddd;"><b>{{ vatAmount }} {{ currency }}</b></td>
              </tr>
              <tr>
                <td style="padding: 5px; border: 1px solid #ddd;">TAX:</td>
                <td style="padding: 5px; border: 1px solid #ddd;"><b>{{ taxAmount }} {{ currency }}</b></td>
              </tr>
              <tr>
                <td colspan="2"><hr style="margin: 10px 0;" /></td>
              </tr>
              <tr>
                <td style="padding: 5px; border: 1px solid #ddd;">Grand Total:</td>
                <td style="padding: 5px; border: 1px solid #ddd;"><b>{{ grandTotalAmount }} {{ currency }}</b></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Note -->
        <div v-if="this.remarks" style="margin-bottom: 20px;">
          <span style="font-weight: bold;">Note: </span>
          <span>{{ remarks }}</span>
        </div>
      </div>
    </div>

    <invoice-sidebar-send-invoice />
    <invoice-sidebar-add-payment
      :invoiceInfo="invoiceInfo"
      :milestoneInfo="milestoneInfo"
      v-on:loadInvoiceInfo="updateInvoiceInfo"
    />
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Logo from "@core/layouts/components/Logo.vue";
import { mapGetters } from "vuex";
import { INVOICE_PAYMENT_ACCESS } from "@/helpers/permissionsConstant";

import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import router from "@/router";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BAlert,
  BLink,
  VBToggle,
  BBadge,
  BTableLite,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
//   import invoiceStoreModule from '../invoiceStoreModule'
import InvoiceSidebarSendInvoice from "./InvoiceSidebarSendInvoice.vue";
import InvoiceSidebarAddPayment from "./InvoiceSidebarAddPayment.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  required,
  email,
  integer,
  between,
  min_value,
} from "vee-validate/dist/rules";
export default {
  name: "InvoiceDetailsView",
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BAlert,
    BLink,
    flatPickr,
    vSelect,
    BTableLite,
    Logo,
    InvoiceSidebarSendInvoice,
    InvoiceSidebarAddPayment,
    ValidationProvider,
    ValidationObserver,
    BBadge,
  },
  data() {
    return {
      itemFormBlankItem: {
        item: null,
        cost: 0,
        qty: 1,
        price: 0,
        description: "",
      },
      invoiceData: {
        items: [
          {
            itemTitle: "",
            cost: 0,
            qty: 1,
            price: 0,
            description: "",
          },
        ],
      },

      INVOICE_PAYMENT_ACCESS,

      invoiceDescription: [],
      invoiceInfo: "",
      milestoneInfo: "",
      officeAddress: "",
      officeEmail: "",
      officeMobile: "",
      accountInfo: "",
      invoiceId: "",
      remarks: "",
      dueDate: "",
      issueDate: "",
      vatAmount: 0,
      taxAmount: 0,
      vatFixedAmount: 0,
      taxFixedAmount: 0,
      currency: "",
      invoiceNo: "",
      invoicePrefix: "",
      invoiceCode: "",

      vatTaxSelected: true,
    };
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],

  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    "invoiceData.items": function () {
      this.initTrHeight();
    },
  },
  mounted() {
    this.initTrHeight();
  },
  async created() {
    window.addEventListener("resize", this.initTrHeight);

    this.loadItems();
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  computed: {
    ...mapGetters({
      filters: "invoiceModule/getFilters",
      permissions: "userModule/getPermissions",
    }),
    subTotalAmount() {
      return this.invoiceData.items.reduce(
        (total, item) => total + item.cost * item.qty,
        0
      );
    },
    grandTotalAmount() {
      return this.subTotalAmount + +this.vatAmount + +this.taxAmount;
    },
  },

  methods: {
    formatDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
      return "Not Set";
    },
    printInvoice() {
      const printSection = document.getElementById('content');
      const originalContent = document.body.innerHTML;

      // Create a new window for printing
      const newWindow = window.open('', '_blank', 'width=600,height=400');
      newWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              /* Add any styles you want for the printed content here */
              body {
                font-family: Arial, sans-serif;
                margin: 20px;
              }
            </style>
          </head>
          <body>
            ${printSection.innerHTML}
          </body>
        </html>
      `);
      newWindow.document.close(); // Close the document
      newWindow.print(); // Trigger the print dialog
      newWindow.close(); // Close the new window
    },
    updateInvoiceInfo() {
      this.invoiceCreated = false;

      this.loadItems();
    },
    lazyLoad(fn, ms) {
      return new Promise((resolve) => setTimeout(() => resolve(fn(), ms)));
    },
    windowPrint() {
      window.print();
    },

    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.invoiceData.items.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0);
      });
    },

    async getSetting(params) {
      return await this.$api.get("/api/settings", {
        params: {
          select: params.select,
        },
      });
    },
    async getInvoiceSettings() {
      return await this.$api.get("api/settings/invoice");
    },
    async getInvoiceInfo() {
      const invoiceId = this.$route.params.id;
      return await this.$api.get(
        `api/invoices/${invoiceId}?include=invoiceDetails,account.country`
      );
    },
    async getMilestoneInfo() {
      const milestoneId = this.$route.params.milestoneId;
      return await this.$api.get(
        `api/milestones/${milestoneId}?include=account.country,project.lead,milestoneForwardFromHistories.forwardFrom,milestoneForwardFromHistories.forwardTo,milestoneForwardToHistories.forwardFrom,milestoneForwardToHistories.forwardTo`
      );
    },

    async getActiveAccountInfo() {
      return await this.$api.get("api/accounts/active/all?include=country");
    },
    async loadItems() {
      try {
        this.invoiceDescription = [];
        const [
          getSetting,
          accounts,
          invoiceSettings,
          invoiceInfo,
          milestoneInfo,
        ] = await Promise.all([
          this.getSetting({
            select: "account_id,address,email,mobile",
          }),
          this.getActiveAccountInfo(),
          this.getInvoiceSettings(),
          this.getInvoiceInfo(),
          this.getMilestoneInfo(),
        ]);

        const today = new Date();

        this.milestoneInfo = milestoneInfo?.data?.data;
        this.invoiceInfo = invoiceInfo?.data?.data;

        this.invoiceCode = invoiceSettings?.data?.data?.invoice_code;
        this.invoicePrefix = invoiceSettings?.data?.data?.invoice_prefix;
        this.invoiceId = this.invoiceInfo?.invoice_no;

        this.issueDate = this.invoiceInfo?.issue_date;

        this.dueDate = this.invoiceInfo?.due_date;
        this.remarks = this.invoiceInfo?.remarks;
        this.invoiceData = {
          items: this.invoiceInfo?.invoiceDetails?.data.map((item) => ({
            itemTitle: item?.title,
            cost: item?.amount,
            qty: item?.quantity,
            price: item?.amount,
            description: item.description,
          })),
        };

        this.vatAmount = this.invoiceInfo?.vat_amount;
        this.taxAmount = this.invoiceInfo?.tax_amount;

        this.currency = this.milestoneInfo?.currency;

        this.officeAddress = getSetting?.data?.data?.address;
        this.officeEmail = getSetting?.data?.data?.email;
        this.officeMobile = getSetting?.data?.data?.mobile;

        if (this.invoiceInfo?.account) {
          this.accountInfo = this.invoiceInfo?.account?.data;
        } else {
          const defaultAccId = getSetting?.data?.data?.account_id;
          this.accountInfo = (accounts?.data?.data || []).find((item) => {
            if (item?.id == defaultAccId) {
              return item;
            }
          });
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async updateInvoice(invoiceItems) {
      const invoiceId = this.$route.params.id;
      return await this.$api.put(`api/invoices/${invoiceId}`, {
        tax_amount: this.taxAmount,
        vat_amount: this.vatAmount,
        sub_total_amount: this.subTotalAmount,
        grand_total_amount: this.grandTotalAmount,
        remarks: this.remarks,
        due_date: this.dueDate,
        items: invoiceItems,
        issue_date: this.issueDate,
      });
    },
    milestoneInvoiceValidationForm: async function () {
      this.$refs.milestoneInvoiceValidation.validate().then(async (success) => {
        if (success) {
          try {
            let invoiceItems = [];

            this.invoiceData?.items.forEach((item) => {
              let data = {};
              data.title = item?.itemTitle;
              data.amount = item?.cost;
              data.quantity = item?.qty;
              data.total_amount = item?.cost * item?.qty;
              data.description = item?.description;
              invoiceItems.push(data);
            });

            try {
              const [invoiceInfo] = await Promise.all([
                this.updateInvoice(invoiceItems),
              ]);

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Invoice Updated Successfully",
                },
              });
            } catch (error) {
              if (error?.response?.data?.message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              }

              if (error?.response?.data?.errors) {
                this.$refs.milestoneInvoiceValidation.setErrors(
                  error?.response?.data?.errors
                );
              }
            }
          } catch (error) {
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.milestoneInvoiceValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

  <style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}

.custom-width {
  max-width: 25rem !important;
}
</style>
