<template>
  <b-sidebar
    id="sidebar-invoice-add-payment"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Enter Account Information & Remarks</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer ref="paymentValidation">
        <b-form v-on:submit.prevent="paymentValidationForm" class="p-2">
          <b-row>
            <!-- select account -->
            <b-col md="12" lg="12" xs="12">
              <b-form-group
                label="Account "
                label-for="account"
                class="required-label"
              >
                <ValidationProvider
                  name="account"
                  v-slot="{ errors }"
                  vid="account_id"
                  rules="required"
                >
                  <v-select
                    placeholder="Choose Account"
                    v-model="accountId"
                    :options="accountIdOption"
                    :reduce="(item) => item.id"
                    label="name"
                    class="custom-font"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col md="12" lg="12" xs="12">
              <b-form-group
                label="Invoice Amount"
                label-for="amount"
                class="required-label"
              >
                <b-form-input
                  id="amount"
                  type="text"
                  v-model="getInvoiceAmount"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="12" lg="12" xs="12">
              <b-form-group label="Currency">
                <b-form-input
                  id="name"
                  type="text"
                  v-model="getInvoiceCurrency"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="12" lg="12" xs="12">
              <b-form-group
                label="Remarks "
                label-for="remarks"
                class="required-label"
              >
                <ValidationProvider
                  name="remarks"
                  v-slot="{ errors }"
                  vid="remarks"
                  rules="required"
                >
                  <b-form-textarea
                    id="remarks"
                    v-model="remarks"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Remarks"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="12" lg="12" xs="12">
              <b-form-group
                :label="getMilestoneAmount != getInvoiceAmount ? 'Milestone Next Payment Deadline' : 'Next Milestone Payment Deadline' "
                label-for="milestone_payment_deadline"
                :class="
                  getMilestoneAmount != getInvoiceAmount ? 'required-label' : ''
                "
              >
                <ValidationProvider
                  name="milestone payment deadline"
                  v-slot="{ errors }"
                  vid="milestone_payment_deadline"
                  :rules="
                    getMilestoneAmount != getInvoiceAmount ? 'required' : ''
                  "
                >
                  <flat-pickr
                    v-model="milestonePaymentDeadline"
                    class="form-control invoice-edit-input"
                    :placeholder="getMilestoneAmount != getInvoiceAmount ? 'Milestone Next Payment Deadline' : 'Next Milestone Payment Deadline' "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <!-- loading button -->
            <div class="d-flex mt-2 ml-2">
              <template v-if="isLoading">
                <b-button class="float-right" variant="primary" disabled>
                  <b-spinner small />
                  Loading...
                </b-button>
              </template>
              <template v-else>
                <b-button
                  type="submit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
              </template>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-row></b-form
        >
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { required, max, size, ext, max_value, min_value } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  name: "InvoiceSidebarAddPayment",
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BCol,
    BRow,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    invoiceInfo: {
      type: Object,
      required: true,
    },
    milestoneInfo: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      accountIdOption: [],
      accountId: "",
      remarks: "",
      isLoading: false,
      invoiceAmount: this.invoiceInfo?.grand_total_amount,
      milestonePaymentDeadline: "",
    };
  },
  computed: {
    getInvoiceAmount() {
      return this.invoiceInfo?.grand_total_amount;
    },
    // getInvoiceInfo() {
    //   return this.milestoneInfo;
    // },

    getMilestoneAmount() {
      return this.milestoneInfo?.grand_amount;
    },
    getProjectId() {
      return this.invoiceInfo?.project_id;
    },
    getInvoiceCurrency() {
      return this.invoiceInfo?.currency;
    },

    getMilestoneId() {
      return this.invoiceInfo?.milestone_id;
    },
    getInvoiceId() {
      return this.invoiceInfo?.id;
    },
  },
  async created() {
    try {
      const [accounts, getSetting] = await Promise.all([
        this.getActiveAccountInfo(),
        this.getSetting({
          select: "account_id",
        }),
      ]);

      this.accountIdOption = (accounts?.data?.data || []).map((item) => {
        let name = `${item?.name} (${item?.currency} ${item?.balance})`;
        let id = item?.id;

        return {
          name,
          id,
        };
      });
      this.accountId = getSetting?.data?.data?.account_id;
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },
  methods: {
    async getSetting(params) {
      return await this.$api.get("/api/settings", {
        params: {
          select: params.select,
        },
      });
    },
    buttonClickHandler() {
      this.$emit("loadInvoiceInfo");
    },

    hiddenModal() {
      this.$bvModal.hide("sidebar-invoice-add-payment");
      // this.resetModal();
    },
    async getActiveAccountInfo() {
      return await this.$api.get("api/accounts/active/all");
    },

    paymentValidationForm: async function () {
      this.$refs.paymentValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;

            await this.$api.post(`/api/milestones/income`, {
              account_id: this.accountId,
              project_id: this.getProjectId,
              milestone_id: this.getMilestoneId,
              invoice_id: this.getInvoiceId,
              remarks: this.remarks,
              milestone_payment_deadline: this.milestonePaymentDeadline,
            });
            this.isLoading = false;
            this.$emit("loadInvoiceInfo");
            // this.hideExpenseModal();
            // this.loadItems();
            // this.onCreateLoad();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Invoice Successfully Paid",
              },
            });

            await this.$router.push({
              name: "admin-invoice-details",
              params: {id: this.$route.params.milestoneId}
            });

          } catch (error) {
            this.isLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.paymentValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.required-label label::after {
  content: " *";
  color: red;
}
</style>
